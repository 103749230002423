<style scoped>
</style>

<template >
  <div class="row mt-4">
    <GolferSelector
      class="col-12 col-md-3"
      :selectedGolfer="selectedGolferId"
      :golfers="golfers"
      @updateGolfer="onGetGolfer($event)"
    >
    </GolferSelector>
    <!-- <b-button variant="primary" @click="showModal" class="small-button">Show Handicap Chart</b-button> -->
    <div class="col-12 mx-auto align-middle">
      <RoundsSummaryTable
        v-if="selectedGolferId && roundReviews"
        @onNavTo="onGetNavTo($event)"
        @handicapChart = "showModal"
        :roundReviews="roundReviews"
        :userid="selectedGolferId"
      ></RoundsSummaryTable>

      <RoundsTable
        v-if="selectedGolferId"
        :filters="filters"
        :roundsInfo="roundsInfo"
        @updateFilters="onGetFilters($event)"
        v-on:round-delete="onRoundUpdated($event)"
        v-on:round-edit="onRoundUpdated($event)"
      ></RoundsTable>
    </div>

    <!-- Modal for PlayerHandicapChart -->
    <b-modal v-model="isModalVisible" title="Player Handicap Chart">
      <PlayerHandicapChart :data="handicapData" :antiHandicap="antiHandicapData" v-if="handicapData && antiHandicapData"></PlayerHandicapChart>
    </b-modal>

  </div>
</template>

<script>
import GolferService from '@/services/GolferService'
import GolferSelector from '@/views/handicapLookup/components/GolferSelector'
import RoundService from '@/services/RoundService'
import UserService from '@/services/UserService'
import RoundsTable from './components/roundsTable/RoundsTable'
import RoundsSummaryTable from './components/roundsSummaryTable/RoundsSummaryTable'
import AimCommonMixin from '@/mixins/aim.common.mixin'
import PlayerHandicapChart from '@/components/PlayerHandicapChart.vue'

export default {
  name: 'HandicapLookup',
  mixins: [AimCommonMixin],
  components: {
    RoundsTable,
    GolferSelector,
    RoundsSummaryTable,
    PlayerHandicapChart
  },
  props: {
    uId: {
      type: [String, Number],
      default: '0'
    }
  },
  data: function () {
    return {
      golfers: [],
      selectedGolferId: null,
      roundReviews: {},
      roundsInfo: {},
      filters: {
        sort: 'date',
        desc: true,
        size: 8
      },
      isModalVisible: false,
      handicapData: [],
      antiHandicapData: []
    }
  },
  mounted () {
    this.setinitUserId()
    this.getGolfers()
    this.$nextTick(() => {
      if (this.selectedGolferId) {
        this.getSelectGolferInfo()
      }
    })
  },
  methods: {
    setinitUserId: function (force = false) {
      console.log('setinitUserId')
      // if has selected golfer id, skip it
      if (this.selectedGolferId && !force) {
        console.log('golfer already selected: ' + this.selectedGolferId)
        return
      }

      // if navigate from other page, want to know
      // result for a particular golfer id
      // get it form route, and search that person
      if (this.uId && +this.uId > 0) {
        this.selectedGolferId = +this.uId
        console.log('golfer id in url: ' + this.selectedGolferId)
        return
      }
      if (this.$route.query.id) {
        // this.selectedGolferId = +this.$route.query.id
        console.log('golfer id in url: ' + this.$route.query.id)
        this.$router.push('/handicapLookup/' + (+this.$route.query.id))
        return
      }
      var user = UserService.getUser()
      // use login user id
      if (user && user.id) {
        this.selectedGolferId = +user.id
        console.log('no golfer, using user: ' + this.selectedGolferId)
        return
      }

      //
      if (this.golfers && this.golfers.length) {
        console.log('no golfer, using first: ' + (+this.golfers[0].id))
        this.$router.push('/handicapLookup/' + (+this.golfers[0].id))
      }
    },
    showModal () {
      this.isModalVisible = true
    },
    onGetNavTo: function (type) {
      this.$router.push({ path: `/handicapDetail/${+this.selectedGolferId}/${type}` })
    },
    onRoundUpdated: function (event) {
      this.getSelectGolferInfo()
    },
    // I'm thinking he might of been been viewing carlos, battles data and say it was june and didnt realize he was viewing the wrong one. Either way, added cache busting api
    async getGolfers () {
      this.$updateloading(1)
      try {
        const response = await GolferService.getGolfers({ noCache: true })
        this.golfers = response.data.golfers
      } catch (error) {
        console.error('Error fetching golfers:', error)
      } finally {
        this.$updateloading(-1)
      }
    },
    async getSelectGolferInfo () {
      this.$updateloading(1)
      try {
        const [roundsResp, handicapResp] = await Promise.all([
          RoundService.getUserRoundsReviews(this.selectedGolferId, {
            ...this.SerializeFiltersMixin(this.filters),
            noCache: true
          }),
          RoundService.getUserHandicaps(this.selectedGolferId, { noCache: true })
        ])
        this.roundReviews = roundsResp.data.rounds_reviews
        this.roundsInfo = roundsResp.data.roundsInfo
        this.handicapData = handicapResp.data.handicaps_five_years
        this.antiHandicapData = handicapResp.data.anti_handicaps_five_years
      } catch (error) {
        console.error('Error fetching golfer info:', error)
      } finally {
        this.$updateloading(-1)
      }
    },
    onGetGolfer: function (golferId) {
      this.filters['page'] = 1
      var user = UserService.getUser()
      if (user && user.id && +user.id === (+golferId)) {
        this.$router.push('/handicapLookup/')
      } else {
        this.$router.push('/handicapLookup/' + (+golferId))
      }
    },
    onGetFilters: function (filters) {
      this.filters = filters
      this.getSelectGolferInfo()
    }
  },
  watch: {
    selectedGolferId: function (val, oldVal) {
      if ((val !== oldVal) && val) {
        this.getSelectGolferInfo()
      }
    },

    uId: function (val, oldVal) {
      if (+val > 0) {
        this.selectedGolferId = +val
      } else {
        this.setinitUserId(true)
      }
    }
  }
}
</script>
<style scoped>
.small-button {
  height: 39px; /* Adjust the height as needed */
}
</style>
